import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Privacy = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Fetch the markdown content
    fetch('/Privacy.md')
      .then(response => response.text())
      .then(text => {
        setContent(text);
      })
      .catch(error => console.error('Error loading privacy policy:', error));
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]} 
        components={{
          // Style tables
          table: props => (
            <table className="min-w-full border border-gray-300 my-4" {...props} />
          ),
          th: props => (
            <th className="border border-gray-300 px-4 py-2 bg-gray-50" {...props} />
          ),
          td: props => (
            <td className="border border-gray-300 px-4 py-2" {...props} />
          ),
          // Style headings
          h1: props => (
            <h1 className="text-3xl font-bold mb-6" {...props} />
          ),
          h2: props => (
            <h2 className="text-2xl font-semibold mt-8 mb-4" {...props} />
          ),
          h3: props => (
            <h3 className="text-xl font-semibold mt-6 mb-3" {...props} />
          ),
          // Style lists
          ul: props => (
            <ul className="list-disc pl-6 mb-4" {...props} />
          ),
          ol: props => (
            <ol className="list-decimal pl-6 mb-4" {...props} />
          ),
          // Style paragraphs
          p: props => (
            <p className="mb-4" {...props} />
          )
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default Privacy;
