import React, { useState, useEffect } from 'react';
import { Cloud, ArrowRight, Brain, Lock, Layout, BookOpen, Twitter, MessageCircle, Sparkles } from 'lucide-react';

const TinyCloudLanding = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth) * 2 - 1,
        y: (e.clientY / window.innerHeight) * 2 - 1
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setEmail('');
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-[#020314]">
      {/* Magical background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated gradient orbs */}
        <div 
          className="absolute w-[800px] h-[800px] rounded-full blur-3xl opacity-30 animate-float"
          style={{
            background: 'linear-gradient(45deg, rgba(138, 43, 226, 0.4), rgba(0, 191, 255, 0.4))',
            transform: `translate(${mousePosition.x * 20}px, ${mousePosition.y * 20}px)`,
            top: '-20%',
            right: '-10%',
          }}
        />
        <div 
          className="absolute w-[600px] h-[600px] rounded-full blur-3xl opacity-20 animate-float-reverse"
          style={{
            background: 'linear-gradient(45deg, rgba(255, 0, 255, 0.3), rgba(0, 255, 255, 0.3))',
            transform: `translate(${mousePosition.x * -20}px, ${mousePosition.y * -20}px)`,
            bottom: '-10%',
            left: '-10%',
          }}
        />
        
        {/* Shimmering overlay */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ0cmFuc3BhcmVudCIvPjxwYXRoIGQ9Ik0wIDAgTDIwMCAyMDBNMjAwIDAgTDAgMjAwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==')] opacity-20 animate-shimmer"></div>
        
        {/* Floating particles */}
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 bg-white rounded-full animate-float-particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: 0.2
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8">
          {/* Logo - adjusted for mobile */}
          <div className="flex justify-center items-center gap-2 mb-6 sm:mb-8 group">
            <div className="relative transform hover:scale-110 transition-all duration-300">
              <Cloud className="h-7 w-7 sm:h-8 sm:w-8 text-cyan-300" />
              <div className="absolute inset-0 animate-ping opacity-50">
                <Cloud className="h-7 w-7 sm:h-8 sm:w-8 text-cyan-300" />
              </div>
              <Sparkles className="absolute -top-2 -right-2 h-3 w-3 sm:h-4 sm:w-4 text-yellow-300 animate-pulse" />
            </div>
            <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-cyan-300 via-purple-300 to-pink-300 bg-clip-text text-transparent drop-shadow-[0_0_15px_rgba(59,130,246,0.5)]">
              TinyCloud
            </span>
          </div>

          {/* Headline - improved mobile sizing */}
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight px-2">
            <span className="bg-gradient-to-r from-cyan-400 via-purple-400 to-pink-400 bg-clip-text text-transparent animate-gradient-x inline-block">
              Your AI, Your Data, Your Future
            </span>
          </h1>

          {/* Core Message - reduced text size and spacing for mobile */}
          <p className="font-space-grotesk text-base sm:text-xl md:text-2xl text-blue-100 mb-4 sm:mb-6 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4">
            <span className="inline-block mb-1 sm:mb-2">An intelligent agent that grows with you,</span>
            <br />
            <span className="inline-block">secured by your personal digital vault.</span>
          </p>
          
          <p className="font-space-grotesk text-base sm:text-lg md:text-xl text-blue-100/90 mb-12 sm:mb-16 max-w-2xl mx-auto leading-relaxed px-2 sm:px-4 italic">
            <span className="text-blue-200">As private as your thoughts.</span>
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              As powerful as your potential.
            </span>
          </p>

          {/* Email Signup - mobile optimized */}
          <div className="w-full max-w-md mx-auto mb-6 sm:mb-8 px-2">
            <iframe
              src="https://embeds.beehiiv.com/dac302e9-6935-4bf0-a350-9adc1d55f797?slim=true"
              data-test-id="beehiiv-embed"
              height={52}
              frameBorder={0}
              scrolling="no"
              style={{ 
                margin: 0, 
                borderRadius: '0.5rem', 
                backgroundColor: 'transparent', 
                width: '100%',
              }}
            />
          </div>

          {/* Social Links - improved mobile stacking */}
          <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-6 mb-10 sm:mb-16 px-2 sm:px-4">
            <a 
              href="https://t.me/+pplkv1XbbU01MDVh" 
              className="px-3 py-1.5 bg-blue-400/10 backdrop-blur-sm border-2 border-blue-400/50 text-blue-100 rounded-lg hover:bg-blue-400/20 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MessageCircle className="h-4 w-4" /> Join Our Telegram <ArrowRight className="h-3 w-3" />
            </a>
            <a 
              href="https://blog.tinycloud.xyz" 
              className="px-3 py-1.5 bg-blue-400/10 backdrop-blur-sm border-2 border-blue-400/50 text-blue-100 rounded-lg hover:bg-blue-400/20 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BookOpen className="h-4 w-4" /> Read our Blog <ArrowRight className="h-3 w-3" />
            </a>
            <a 
              href="https://x.com/TinyCloudOrg" 
              className="px-3 py-1.5 bg-blue-400/10 backdrop-blur-sm border-2 border-blue-400/50 text-blue-100 rounded-lg hover:bg-blue-400/20 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="h-4 w-4" /> Follow on X <ArrowRight className="h-3 w-3" />
            </a>
          </div>

          {/* Features - enhanced mobile grid */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 mb-10 sm:mb-16 px-2 sm:px-4">
            {[
              { icon: Brain, title: "Learns From You", desc: "Understands your context and preferences from your digital footprint" },
              { icon: Layout, title: "Works Everywhere", desc: "Connects seamlessly with all other TinyCloud powered apps" },
              { icon: Lock, title: "Completely Private", desc: "Your data never leaves your control" }
            ].map(({ icon: Icon, title, desc }) => (
              <div key={title} className="flex flex-col items-center group p-4 sm:p-5 bg-blue-400/5 rounded-lg backdrop-blur-sm border border-blue-400/10 hover:border-blue-400/20">
                <div className="relative mb-3 sm:mb-4">
                  <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-blue-400" />
                  <div className="absolute inset-0 animate-ping opacity-50 group-hover:opacity-100">
                    <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-blue-400" />
                  </div>
                </div>
                <h3 className="font-medium text-blue-100 mb-2 text-base sm:text-lg">{title}</h3>
                <p className="text-sm text-blue-200/80 text-center leading-relaxed">
                  {desc}
                </p>
              </div>
            ))}
          </div>

          {/* Bottom Message - adjusted for mobile */}
          <p className="text-xs sm:text-sm text-blue-200/60 px-2">
            Join the future of personal AI - where your digital world works for you
          </p>
          <div className="mt-4">
            <a 
              href="/privacy"
              className="text-xs sm:text-sm text-blue-200/60 hover:text-blue-200/80 underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TinyCloudLanding;